import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { StrictMode, startTransition, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { getEnvironment } from './utils/get-environment.client'

Sentry.init({
  enabled: getEnvironment() === 'production',
  environment: getEnvironment(),
  dsn: 'https://47adbcb60489f58061bee37b6b9e09a8@o4506338009088000.ingest.sentry.io/4506338015969280',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches)
    })
  ]
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
